@import "../../assets_global/scss/partials/_vars.scss";

.social-block {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 65px auto;

    @media(max-width: 576px){
       justify-content: space-around;
    }

    .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 250px; width: 250px;
        background: none;
        vertical-align: middle;
        cursor: pointer;
        transition: all 0.5s;

        @media(max-width: 576px){
           display: block;
           margin: 10px auto;
        }
    }
   
    /* 
    .facebook {
        position: relative;
        background: url(../../assets_global/images/bg-fb-img.png) 0px 0px no-repeat;
        background-size: cover;
        &:hover {
            border-color: $kl-gold;
            background-color: $white;
        }
    }
    .facebook::after {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        content: "";
        background: url(../../assets_global/images/bg-fb-img.png) 0px -250px no-repeat;
        background-size: cover;
        opacity: 0;
        transition: opacity 0.5s;
    }
    .facebook:hover::after {
        opacity: 1;
        transition: opacity 0.5s;
    }
    .btn-twitter {
        position: relative;
        background: url(../../assets_global/images/bg-tw-img.png) 0px 0px no-repeat;
        background-size: cover;
        &:hover {
            border-color: $kl-gold;
            background-color: $white;
        }
    }
    .btn-twitter::after {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        content: "";
        background: url(../../assets_global/images/bg-tw-img.png) 0px -250px no-repeat;
        background-size: cover;
        opacity: 0;
        transition: opacity 0.5s;
    }
    
    .btn-twitter:hover::after {
        opacity: 1;
        transition: opacity 0.5s;
    } */
}