

@font-face {
    font-family: 'ubuntubold';
    src: url('../fonts/ubuntu/ubuntu-b-webfont.woff2') format('woff2'),
         url('../fonts/ubuntu/ubuntu-b-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'ubuntumedium';
    src: url('../fonts/ubuntu/ubuntu-m-webfont.woff2') format('woff2'),
         url('../fonts/ubuntu/ubuntu-m-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'ubunturegular';
    src: url('../fonts/ubuntu/ubuntu-r-webfont.woff2') format('woff2'),
         url('../fonts/ubuntu/ubuntu-r-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}