@import "../../assets_global/scss/partials/_vars.scss";

.block-1 {
    display: block;
    text-align: center;

    .top-line {
        position: relative;
        height: 17px;
        width: 100%;
        background: $white;
        text-align: center;
    }

    span.circle {
        position: relative;
        display: inline-block;
        height: 180px; width: 180px;
        margin: -110px auto 0;
        border-radius: 110px;
        background: $white;
        a {
            border: none;
            outline: none;
            text-decoration: none;
            cursor: pointer;
        }
        img {
            display: block;
            width: 98px;
            margin: 110px auto 0;
        }
    }
}
.block-2 {
    text-align: center;

    img.kawhi-logo {
        display: block;
        width: 75%;
        max-width: 400px; 
        height: auto;
        margin: 50px auto;
    }
    h1 {
        display: block;
        font-family: 'ubuntubold', Arial, Helvetica, sans-serif;
        font-size: 1.85rem;
        letter-spacing: 1.25px;
        text-align: center; 
        text-transform: uppercase;

        
        .break-m {
            display:none;
            @media(max-width: 576px){
                display: block;
            }
        }
        
    }
    p {
        width: 85%;
        margin: 8px auto;
        color: $white;
        font-family: 'ubuntubold', Arial, Helvetica, sans-serif;
        font-size: 1.3rem;
        line-height: 1.4;

        @media(max-width: 576px){
            font-size: 1.15rem;
        }

        .break {
            @media(max-width: 576px){
                display: none;
            }
        }
    }
    span.line {
        display: inline-block;
        height: 1px;
        width: 120px;
        background: $white;
        margin: 35px auto;
    }
    h2 {
        font-family: 'ubuntubold', Arial, Helvetica, sans-serif;
        font-size: 1.85rem;
        letter-spacing: 1.25px;
        text-align: center; 
        text-transform: uppercase;
    }
    p.download {
        font-size:0.85rem;
    }
}

.block-3 {
    overflow: hidden;
    text-align: center;
    
    .container {
        display: block;
        max-width: 530px;
        margin: 15px auto 0;
    }
    h2 {
        font-family: 'ubuntubold', Arial, Helvetica, sans-serif;
        font-size: 1.85rem;
        letter-spacing: 1.25px;
        text-align: center; 
        text-transform: uppercase;
    }
    a.box {
        position: relative;
        display: block;
        height: auto; 
        max-width:515px;
        margin: 0px auto 25px;
        //border: 5px solid $white;
       // background: url(../../assets_global/images/bg-cir-arrow-sqr.png) 0px 0px no-repeat;
       // background-size: 250px 500px;
       // background-position-x: 275px;
        vertical-align: top;
        text-align: left;
        cursor: pointer;

        @media(max-width: 576px){
            width: 90%;
            
            p {
                width: 100%;
            }
        }

        img {
            display: block;
            width: 100%;
            margin: 0 auto;
        }
        h4 {
            display: block;
            position: absolute;
            width: 100%;
            top: 20px;
            color: $kl-gold;
            font-family: 'ubuntubold', Arial, Helvetica, sans-serif;
            font-size: 0.95rem;
            text-align: center;
            text-transform: uppercase;
            @media(max-width: 576px){
                top: 10px;
                font-size: 0.8rem;
            }
        }
        h3 {
            margin-top: 155px;
            color: $white;
            font-size: 1rem;
            font-weight: 600;
            letter-spacing: 2px;
            text-transform: uppercase;
        }
        
        p{
            display: inline-block;
            width: 250px;
            margin-top: 75px;
            padding: 25px 0;
            border-right: 2px solid $white;
            color: $white;
            font-family: 'ubuntubold', Arial, Helvetica, sans-serif;
            font-size: 0.95rem;
            text-align: center;

            @media(max-width: 425px){
                font-size: 0.8rem;
            }
        }
        
        span {
            font-weight: bold;
        }

        &:hover{
           // nothing
        }
    }
    
    
    /* .box::after {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        content: "";
        background: url(../../assets_global/images/bg-cir-arrow-sqr.png) 0px -235px no-repeat;
        background-size: 250px 500px;
        background-position-x: 275px;
        background-position-y: -250px;
        opacity: 0;
        transition: opacity 0.5s;
    }
    
    .box:hover::after {
        opacity: 1;
        transition: opacity 0.5s;
    } */
   
    a {
        color:$white;
        transition: opacity 0.5s;
        &:hover {
            color: $kl-gold;
        }
    }
    h3 {
        margin: 35px auto 0;
        color: $white;
        font-size: 1.3rem;
        font-weight: 600;
        letter-spacing: 2px;
        text-transform: uppercase;
    }
    p {
        display: block;
        width: 85%;
        margin: 20px auto;
        color: $white;
        font-family: 'ubuntubold', Arial, Helvetica, sans-serif;
        font-size: 0.9rem;
        letter-spacing: 1px;
        line-height: 1.5;

        @media(max-width: 425px){
            font-size: 0.8rem;
        }
    }
    span.line {
        display: inline-block;
        height: 1px;
        width: 120px;
        background: $white;
        margin: 15px;
    }

    
    
}

.block-badges-added {
    overflow: hidden;
    text-align: center;
    
    .container {
        display: block;
        max-width: 730px;
        margin: 15px auto 0;
    }
    span.line {
        display: inline-block;
        height: 1px;
        width: 120px;
        background: $white;
        margin: 15px;
    }
    h2 {
        font-family: 'ubuntubold', Arial, Helvetica, sans-serif;
        font-size: 1.85rem;
        letter-spacing: 1.25px;
        text-align: center; 
        text-transform: uppercase;
    }
    .badgewrap {
        overflow: hidden;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        justify-content: space-between;
        margin: 20px auto;
        padding: 0 10px;

        @media(max-width: 576px){
            display: block;
            text-align: center;
        }

        span {
            display: block;
            width: 100%;
            margin: 20px 0;

            a{
                margin: 10px;
            }
        }

    }
    a.badge {
        display: inline-block;
        width: 32%;
        cursor: pointer; 

        @media(max-width: 576px){
            width: 42%;
            margin: 20px 5px;
        }
        
        img { 
            display: block;
            width: 100%;
        }
    }
    p {
        display: block;
        width: 85%;
        margin: 5px auto 10px;
        color: $white;
        font-family: 'ubuntubold', Arial, Helvetica, sans-serif;
        font-size: 0.9rem;
        letter-spacing: 1px;
        line-height: 1.5;
    }
}

.block-kawhi-kind {
    overflow: hidden;
    text-align: center;
    
    .container {
        display: block;
        max-width: 730px;
        margin: 15px auto 0;
    }
    span.line {
        display: inline-block;
        height: 1px;
        width: 120px;
        background: $white;
        margin: 0px auto 30px;
    }
    h2 {
        font-family: 'ubuntubold', Arial, Helvetica, sans-serif;
        font-size: 1.85rem;
        letter-spacing: 1.25px;
        text-align: center; 
        text-transform: uppercase;
    }
    a img {
        position: relative;
        display: block;
        height: auto;
        width: 100%;
        max-width: 515px;
        margin: 0px auto 0px;
        vertical-align: top;
        text-align: left;
        cursor: pointer;
        @media(max-width: 576px){
            width: 90%;
        }
    }
    p {
        display: block;
        width: 85%;
        max-width: 450px;
        margin: 20px auto;
        color: $white;
        font-family: 'ubuntubold', Arial, Helvetica, sans-serif;
        font-size: 0.9rem;
        letter-spacing: 1px;
        line-height: 1.5;
    }
    ul.logo-list {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        list-style-type: none;
        padding: 0;
        margin: 0 auto 15px;

        li{
            display: inline-block;
            max-width: 20%;
            margin: 0 8px;
            vertical-align: middle;

            @media(max-width: 576px){
                width: 18%;
            }

            a {
                display: block;
                border: none;
                outline: 0;
            }
            img {
                display: block;
                width: 100%;
            }
        }
    }
}

.block-rally {
    overflow: hidden;
    
    .container {
        max-width: 530px;
        margin: 30px auto;
        text-align: center;
    }
    img.rally-city {
        display: block;
        margin: 25px auto 25px;
    }
    h2 {
        font-family: 'ubuntubold', Arial, Helvetica, sans-serif;
        font-size: 1.85rem;
        letter-spacing: 1.25px;
        text-align: center; 
        text-transform: uppercase;
    }
    h3 {
        font-family: 'ubuntubold', Arial, Helvetica, sans-serif;
        font-size: 1.9rem;
        letter-spacing: 2px;
        text-transform: uppercase;
    }
    span.line {
        display: inline-block;
        height: 1px;
        width: 120px;
        background: $white;
        margin-top: 30px;
    }
    p {
        width: 85%;
        margin: 15px auto 0;
        color: $white;
        font-family: 'ubuntubold', Arial, Helvetica, sans-serif;
        font-size: 0.9rem;
        letter-spacing: 1px;
        line-height: 1.5;

        @media(max-width: 425px){
            font-size: 0.8rem;
        }
    }
    
}

.block-5 {
    margin-top: 65px;
    text-align: center;

    span.circle {
        display: inline-block;
        height: 180px; width: 180px;
        margin: 0px auto -100px;
        border-radius: 110px;
        background: $white;
        
        img {
            display: block;
            margin:10px auto 0;
        }
    }
    .bottom-line {
        position: relative;
        height: 17px;
        width: 100%;
        background: $white;
        text-align: center;
    }
    
}