.photo-block {
    display: flex;
    /* Then we define the flow direction 
       and if we allow the items to wrap 
     * Remember this is the same as:
     * flex-direction: row;
     * flex-wrap: wrap;
     */
    flex-flow: row wrap;
    /* Then we define how is distributed the remaining space */
    justify-content: space-around;
    // keeps aspect ratio of img
    align-items: center;
    padding: 20px 0 0px 0;

    img {
        display: block;
        width: 30%;
        margin: 5px 0;

        @media(max-width: 576px){
            width: 90%;
            margin: 5px auto;
        }
    }
    a {
        display: block;
        width: 30%;
        margin: 5px 0;
        cursor: pointer;
        outline: none;
        border: 0;

        @media(max-width: 576px){
            width: 90%;
            margin: 5px auto;
        }

        img {
           width: 100%;
        }
    }
}