@import "_vars.scss";
html {
    scroll-behavior: smooth;
    width:100%; 
    height:100%; 
    background-attachment: fixed;
    height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}
*
{
    margin:0px;
    padding:0px;
}

#root {
    display: block;
    position: relative;
    overflow: hidden;
    z-index: 1;
    background-color: $black;
}
// General block, wrapper for full width containers, always 100% width
.block {
    display:block;
    position: relative;
    overflow:hidden;
    width: 100%;
    text-align: center;
}
// General content container
.container {
    display: block;
    position: relative;
    // $screen-md = 992px;
    width: 100%;
    max-width: $screen-lg;
    margin: 0 auto;
    text-align: center;

    @media(max-width: $screen-lg){
        max-width: 1200px;
    }
}

.content {
    display: block;
    margin: 0 auto;
    text-align: center;
    
    @media( max-width: $screen-lg){
        width: 96%;
    }
    @media( max-width: $screen-md){
        width: 90%;
    }
}

h1,h2,h3,h4,h5 {
    font-family: 'ubunturegular', Arial, Helvetica, sans-serif;
    font-weight: 300;
    color: $white;
}
a {
    transition: 0.4s ease  all;
    outline: none;
    border: none;
}
a:hover {
    color: rgba(162, 112, 181, 1);
}

/* HELPERS */

.show {
    display: block;
}
.hide {
    display: none!important;
}
.text-left {
    text-align: left;
}
.text-right {
    text-align: right;
}
.side-half {
    display: inline-block;
    width:49%;
}

.not-visible {
  visibility: hidden !important;
  opacity: 0 !important;
  transition: opacity 3.25s ease-in-out;
}

br.mobile {
    display: none;
    @media (max-width: $screen-md){
        display: block;
    }
}
html:lang(en) {
   
} 
html:lang(fr) {
    
} 
