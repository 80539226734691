/* VARS - BTA =========================================
======================================================= */
$white: rgba(255, 255, 255, 1);
$black: rgba(0, 0, 0, 1);
$kl-red: rgba(228, 3, 3, 1);
$kl-yellow: rgba(247, 224, 23, 1);
$kl-gold: rgba(138, 117, 80, 1);
$kl-grey: rgba(81, 83, 85, 1);

// Container size


// Small tablets and large smartphones (landscape view)
$screen-sm: 576px;

// Small tablets (portrait view)
$screen-md: 768px;

// Tablets and small desktops
$screen-lg: 992px;

// Large tablets and desktops
$screen-xl: 1200px;

// Super large
$screen-max: 1600px;